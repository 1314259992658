import { Box, Button, FormLabel, Typography, TextField, MenuItem, FormControl, FormControlLabel, RadioGroup, Radio, Slider, Switch } from '@mui/material';
import withAuth from '../../../HOC/withAuth';
import React, { useEffect, useState, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { countChineseAndEnglish } from '../../../util';
import http from '../../../http';


import WatermarkPreview from '../../../components/WatermarkPreview';
import SettingBar from './SettingBar';

const Watermark = inject("userStore", 'snackbarStore')(observer(({ userStore, snackbarStore }) => {

    const watermarkPreviewRef = useRef(null);
    const [openWatermark, setOpenWatermark] = useState(false)
    const [watermark, setWatermark] = useState(null);
    const [watermarkError, setWatermarkError] = useState(false);
    const [position, setPosition] = useState("top-right");
    const [watermarkStyle, setWatermarkStyle] = useState("single");
    const [watermarkAlpha, setWatermarkAlpha] = useState(0.5);
    const [fontSize, setFontSize] = useState(35)

    const handleInputChange = (event) => {
        let { name, value } = event.target;
        if (name == "watermark") {
            setWatermark(value);
            setWatermarkError(countChineseAndEnglish(value) > 5);
        } else if (name == "openWatermark") {
            value = event.target.checked;
            setOpenWatermark(value);
        } else if (name == "position") {
            setPosition(value);
        } else if (name == "fontSize") {
            setFontSize(value);
        } else if (name == "style") {
            setWatermarkStyle(value);
        } else if (name == "alpha") {
            setWatermarkAlpha(value);
        }
    };

    const onSaveWatermark = (event) => {
        http.post('/user/saveWaterMark', {
            openWatermark,
            watermark,
            position,
            fontSize,
            watermarkStyle,
            watermarkAlpha
        })
            .then(response => {
                snackbarStore.showSnackbar(t("Watermark saved successfully"))
                userStore.openWatermark = true;
                userStore.watermark = watermark;
                userStore.watermarkFontSize = fontSize;
                userStore.watermarkPosition = position;
                userStore.watermarkStyle = watermarkStyle;
                userStore.watermarkAlpha = watermarkAlpha;
            })
            .catch(error => { });
    }

    const { t } = useTranslation();

    useEffect(() => {
        setOpenWatermark(userStore.openWatermark);
        setWatermark(userStore.watermark);
        setFontSize(userStore.watermarkFontSize || 35);
        setPosition(userStore.watermarkPosition || "center");
        setWatermarkStyle(userStore.watermarkStyle || "single");
        setWatermarkAlpha(userStore.watermarkAlpha);
    }, [userStore.openWatermark, userStore.watermark, userStore.watermarkFontSize, userStore.watermarkPosition, userStore.watermarkStyle, userStore.watermarkAlpha]);

    useEffect(() => {
        if (watermarkPreviewRef.current) {
            watermarkPreviewRef.current.drawWatermark(openWatermark, watermarkStyle, watermark, watermarkAlpha, fontSize, position);
        }
    }, [watermarkStyle, openWatermark, watermark, watermarkAlpha, fontSize, position])

    return (
        <Box sx={{ display: "flex", flexDirection: "row", height:"100%" }} >
            <SettingBar active="watermark" />
            <Box sx={{ display: "flex", flexDirection: "column", width: 900, p:2, margin: "10px auto", background: "white", borderRadius: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                    <Typography variant="h3">
                        {t("Global Watermark Setting")}
                    </Typography>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    <WatermarkPreview ref={watermarkPreviewRef} />
                </Box>
                <Box sx={{ ml: 2, display: "flex", flexDirection: "column" }}>
                    <FormControlLabel
                        name='openWatermark'
                        control={<Switch checked={openWatermark} onChange={handleInputChange} />}
                        label={t("OpenWatermark")}
                    />
                    {openWatermark && <Box sx={{ display: "flex", flexDirection: "column", mt: 1 }}>

                        <Box sx={{ mt: 1 }}>
                            <FormControl>
                                <FormLabel>{t("Watermark")}</FormLabel>
                                <TextField
                                    size="small"
                                    sx={{ width: 300 }}
                                    placeholder={t("Watermark")}
                                    type="text"
                                    name="watermark"
                                    value={watermark || ""}
                                    onChange={handleInputChange}
                                    error={!!watermarkError}
                                    helperText={watermarkError ? t("Watermark must less than 5 letters") : ""}
                                />
                            </FormControl>
                        </Box>

                        <Box sx={{ mt: 3 }}>
                            <FormControl>
                                <FormLabel>{t("Style")}</FormLabel>
                                <RadioGroup
                                    row
                                    name="style"
                                    value={watermarkStyle}
                                >
                                    <FormControlLabel value="single" control={<Radio />} label={t("Single")} onChange={handleInputChange} />
                                    <FormControlLabel value="fill" control={<Radio />} label={t("Fill")} onChange={handleInputChange} />
                                </RadioGroup>
                            </FormControl>
                        </Box>

                        <Box sx={{ mt: 3 }}>
                            <FormControl>
                                <FormLabel>{t("Alpha")}</FormLabel>
                                <Slider
                                    sx={{ width: 300 }}
                                    max={1}
                                    min={0}
                                    value={watermarkAlpha}
                                    step={0.1}
                                    name='alpha'
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                        </Box>

                        {watermarkStyle == "single" && <Box sx={{ mt: 3 }}>
                            <FormControl>
                                <FormLabel>{t("Position")}</FormLabel>
                                <RadioGroup
                                    row
                                    name="position"
                                    value={position}
                                >
                                    <FormControlLabel value="top-left" control={<Radio />} label={t("Top Left")} onChange={handleInputChange} />
                                    <FormControlLabel value="top-right" control={<Radio />} label={t("Top Right")} onChange={handleInputChange} />
                                    <FormControlLabel value="bottom-left" control={<Radio />} label={t("Bottom Left")} onChange={handleInputChange} />
                                    <FormControlLabel value="bottom-right" control={<Radio />} label={t("Bottom Right")} onChange={handleInputChange} />
                                    <FormControlLabel value="center" control={<Radio />} label={t("Center")} onChange={handleInputChange} />
                                </RadioGroup>
                            </FormControl>
                        </Box>}
                        <Box sx={{ mt: 3 }}>
                            <FormControl>
                                <FormLabel>{t("Font Size")}</FormLabel>
                                <RadioGroup
                                    row
                                    name="fontSize"
                                    value={fontSize}
                                >
                                    <FormControlLabel value="25" control={<Radio />} label={t("Small")} onChange={handleInputChange} />
                                    <FormControlLabel value="35" control={<Radio />} label={t("Medium")} onChange={handleInputChange} />
                                    <FormControlLabel value="55" control={<Radio />} label={t("Large")} onChange={handleInputChange} />
                                    <FormControlLabel value="85" control={<Radio />} label={t("X-Large")} onChange={handleInputChange} />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Box>}
                    <Box sx={{ display: "flex", mt: 1, flexDirection: "row" }}>
                        <Box sx={{ flexGrow: 1 }}></Box>
                        <Button onClick={onSaveWatermark} variant="contained" sx={{ width: 60 }} color="primary" >
                            {t("Save")}
                        </Button>
                    </Box>

                </Box>
            </Box>
        </Box>
    );
}));

export default withAuth(Watermark);
