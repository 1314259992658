import { Alert, Box, Button, Chip, FormControlLabel, Grid, IconButton, Switch, Tab, Tabs, TextField, Typography } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CopyNames from '../../../components/CopyNames';
import CustomTabLabel from '../../../components/CustomTabLabel';
import Empty from '../../../components/Empty';
import ImageDialog from '../../../components/ImageDialog';
import { ImageGallery } from '../../../components/ImageGallery';
import LocalSperater from "../../../components/LocalSperater";
import OnUploadedConfirmDialog from "../../../components/OnUploadedConfirmDialog";
import StepsSideBar from '../../../components/StepsSideBar';
import withAuth from '../../../HOC/withAuth';
import http from '../../../http';
import FileUploader from '../FileUpload';

import ReportIcon from '@mui/icons-material/Report';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import CheckIcon from '@mui/icons-material/Check';
import { userStore } from '../../../stores/UserStore';
import theme from '../../../theme';

const Step3 = inject('pictureStore', 'photoStore', 'snackbarStore', 'dialogStore')(observer(({ pictureStore, photoStore, snackbarStore, dialogStore }) => {
    const { orderId } = useParams();
    const [order, setOrder] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [colnumnNum, setColnumnNum] = useState(6)
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const [uploadResult, setUploadResult] = useState({})
    const navigator = useNavigate()
    const { t, i18n } = useTranslation();

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        switch (newValue) {
            case 0:
                pictureStore.setPreviewPicturesFilter("all")
                break;
            case 1:
                pictureStore.setPreviewPicturesFilter("selected")
                break;
            case 2:
                pictureStore.setPreviewPicturesFilter("unselected")
                break;
        }
    }
    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value)
    }

    const handlePictureDelete = () => {
        dialogStore.openDialog("Are you sure you want to delete those pictures?", () => {
            pictureStore.deletePictrus(orderId, pictureStore.multiSelections)
            if (order?.copyToDelivery) {
                let pictureNames = pictureStore.multiSelections.map(id => pictureStore.pictures.find(picture => picture.id == id).originalFileName)
                photoStore.deletePhotoWithName(orderId, pictureNames)
            }
        })
    }

    const nextStep = useCallback(() => {
        navigator(`/user/orders/${orderId}/step2`)
    }, [navigator]);

    const copyToDelivery = useCallback((e) => {
        const copy = e.target.checked
        http.put(`/user/order/${orderId}`, {
            copyToDelivery: copy
        }).then(response => {
            let newOrder = response.data;
            setOrder(newOrder);
            snackbarStore.showSnackbar(t("Update Success"));
        })
    }, []);

    const fetchOrderData = useCallback(() => {
        http.get(`/user/order/${orderId}`)
            .then(response => {
                let orderData = response.data?.order
                setOrder(orderData);
                pictureStore.reset()
                pictureStore.setCover(orderData.Album.cover)
                photoStore.setPhotos(response.data?.photos);
                pictureStore.setPictures(response.data?.pictures)
                pictureStore.setTags(response.data?.tags)
                pictureStore.setAlbumClaimed(orderData?.Album?.claimed)

            })
            .catch(error => { });
    }, [orderId, pictureStore]);

    useEffect(() => {
        fetchOrderData();
    }, [fetchOrderData]);

    if (!order) {
        return <div>Loading...</div>;
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "row", height: "100%" }} >
            <StepsSideBar order={order} orderId={orderId} />
            <Box sx={{ flex: 1, display: 'flex', flexDirection: "column", marginBottom: 2 }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    pt: 3,
                    pb: 3,
                    pl: 4,
                    pr: 4,
                    borderBottom: 1,
                    borderBottomColor: theme.palette.grey[300],
                    borderBottomStyle: "solid",
                    backgroundColor: theme.palette.grey[50],
                }}>
                    <Typography variant="h3"> {t("Picture Management")}</Typography>
                    <Alert severity="warning" sx={{ width: "100%", mt: 2 }}>
                        {t("One picture can only appear in one order. So if you upload a picture that already exists in the order, it will be ignored.")}
                    </Alert>
                </Box>
                <Box sx={{ pl: 4, pr: 4, pt: 2, pb: 2, flex: 1, display: 'flex', flexDirection: "column" }}>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: "center", backgroundColor: "#ffffff", mt: 1, mb: 2 }}>
                            <Typography variant="subtitle2" sx={{ ml: 1 }}>{t("Filter")}</Typography>
                            <TextField
                                sx={{ ml: 1, maxWidth: 300 }}
                                fullWidth
                                size='small'
                                value={searchText}
                                onChange={handleSearchTextChange}
                            />
                            <Box>
                            <Box sx={{ml:2}}>
                                {pictureStore.tags.map(tag => {
                                    return <Chip
                                        key={tag.id}
                                        label={tag.name}
                                        icon={ pictureStore.haveTagFilter(tag.id) && <CheckIcon color={ pictureStore.haveTagFilter(tag.id)?"#fff":"#000"} />}
                                        sx={{ lineHeight:"1rem", mr:1, backgroundColor:pictureStore.haveTagFilter(tag.id)?tag.color:"#efefef", color: pictureStore.haveTagFilter(tag.id)?"#fff":"#000" }}
                                        onClick={() => {
                                            if(pictureStore.haveTagFilter(tag.id)){
                                                pictureStore.removeTagFilter(tag.id)
                                            }else{
                                                pictureStore.addTagFilter(tag.id)
                                            }
                                        }}
                                    />
                                })}
                            </Box>
                           
                        </Box>
                        </Box>
                       
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            {pictureStore.albumClaimed && <CopyNames pictures={pictureStore.selectedPictures} />}
                            {pictureStore.albumClaimed && <LocalSperater pictures={pictureStore.selectedPictures} />}
                            <FileUploader
                                size={"small"}
                                albumId={order?.Album?.id}
                                openWatermark={order?.openWatermark}
                                watermarkStyle={order?.watermarkStyle}
                                watermark={order?.watermark}
                                watermarkAlpha={order?.watermarkAlpha}
                                watermarkPosition={order?.watermarkPosition}
                                watermarkFontSize={order?.watermarkFontSize}
                                copyToDelivery={order?.copyToDelivery}
                                onStartUpload={(num) => {
                                    snackbarStore.showSnackbar(t("Uploading {{num}} pictures", { num: num }))
                                }}
                                onUploaded={(params) => {
                                    setUploadResult(params)
                                    setConfirmDialogOpen(true)
                                }} />
                        </Box>

                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                        <Tabs sx={{ flex: 1 }} value={tabValue} onChange={handleChange}>
                            <Tab label={<CustomTabLabel label="全部" num={pictureStore.pictures.length} />} />
                            <Tab label={<CustomTabLabel label="已选" num={pictureStore.selectedPictures.length} />} />
                            <Tab label={<CustomTabLabel label="未选" num={pictureStore.unSelectedPictures.length} />} />
                        </Tabs>
                        <IconButton onClick={() => {
                            let newColnumnNum = colnumnNum - 1
                            if (newColnumnNum < 1) {
                                newColnumnNum = 1
                            }
                            setColnumnNum(newColnumnNum)
                        }}>
                            <ZoomInIcon />
                        </IconButton>
                        <IconButton onClick={() => {
                            let newColnumnNum = colnumnNum + 1
                            if (newColnumnNum > 6) {
                                newColnumnNum = 6
                            }
                            setColnumnNum(newColnumnNum)
                        }}>
                            <ZoomOutIcon />
                        </IconButton>
                        <Button onClick={() => {
                            pictureStore.setMultiMode(!pictureStore.multiMode)
                        }}>
                            {pictureStore.multiMode ? t("Cancel") : t("Multi select")}
                        </Button>
                        {pictureStore.multiSelections.length > 0 && <Button onClick={handlePictureDelete}>
                            {t("Delete")}({pictureStore.multiSelections.length})
                        </Button>}
                    </Box>
                    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: "column" }}>
                        {userStore.level >= 1 && <Box sx={{ p: 1, display: 'flex', flexDirection: "column" }}>
                            <FormControlLabel
                                onChange={copyToDelivery}
                                control={<Switch checked={order.copyToDelivery} />}
                                label={t("Add to nagetive folder of dilivery at the same time while uploading, And deletion will be synced too.")} />
                            {order.copyToDelivery && <Box sx={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"flex-start"}}>
                                <ReportIcon sx={{color:theme.palette.primary.main}}/>
                                <Typography variant='body2' sx={{ml:1}}>
                                    {t("The number of pictures in the negative folder of the delivery is limited to {{num}}.", { num: userStore.limit })}
                                </Typography>
                            </Box>}
                        </Box>}


                        <Box sx={{ display: 'flex', flexWrap: 'wrap', pt: 1, pb: 1, flex: 1 }}>
                            {pictureStore?.pictures?.length > 0 ? <Grid container spacing={2}>
                                {pictureStore?.previewPictures?.map((file) => {
                                    if (searchText && !file.fileName.includes(searchText)) {
                                        return null
                                    }
                                    return <ImageGallery
                                        copyToDelivery={order?.copyToDelivery}
                                        isCover={pictureStore.cover == file.id}
                                        orderId={orderId}
                                        albumId={order?.Album.id}
                                        picture={file}
                                        key={file.id}
                                        multiMode={pictureStore.multiMode}
                                        pictureStore={pictureStore}
                                        photoStore={photoStore}
                                        albumClaimed={pictureStore.albumClaimed}
                                        colnumnNum={12 / colnumnNum}
                                        manageMode={true}
                                    />
                                })}
                            </Grid> : <Empty message={t("No picture available.")} >
                                <FileUploader
                                    sx={{ mt: 2 }}
                                    size={"large"}
                                    albumId={order?.Album?.id}
                                    openWatermark={order?.openWatermark}
                                    watermarkStyle={order?.watermarkStyle}
                                    watermark={order?.watermark}
                                    watermarkAlpha={order?.watermarkAlpha}
                                    watermarkPosition={order?.watermarkPosition}
                                    watermarkFontSize={order?.watermarkFontSize}
                                    onStartUpload={(num) => {
                                        snackbarStore.showSnackbar(t("Uploading {{num}} pictures", { num: num }))
                                    }}
                                    copyToDelivery={order?.copyToDelivery}
                                    onUploaded={(params) => {
                                        setUploadResult(params)
                                        setConfirmDialogOpen(true)
                                    }} />
                            </Empty>}

                        </Box>

                    </Box>

                </Box>

            </Box>
            <ImageDialog pictureStore={pictureStore} />
            <OnUploadedConfirmDialog open={confirmDialogOpen} title={t("Upload Complete")} onConfirm={() => {
                setConfirmDialogOpen(false)
            }} >
                <Typography variant='body2'>{t("Uploaded {{pictureNum}} pictures, seccess: {{successFileCount}} ,fail: {{failFileCount}},exists {{existFileCount}}.",
                    {
                        pictureNum: uploadResult?.pictureNum,
                        successFileCount: uploadResult?.successFileCount,
                        existFileCount: uploadResult?.existFileCount,
                        failFileCount: uploadResult?.failFileCount
                    })}</Typography>
                {order?.copyToDelivery && <Typography variant='body2'>{t("Uploaded {{pictureNum}} pictures to nagetive folder of dilivery, seccess: {{successPhotoCount}}, fail:{{failPhotoCount}} ,exists {{existPhotoCount}}.",
                    {
                        pictureNum: uploadResult?.pictureNum,
                        successPhotoCount: uploadResult?.successPhotoCount,
                        existPhotoCount: uploadResult?.existPhotoCount,
                        failPhotoCount: uploadResult?.failPhotoCount
                    })}</Typography>
                }
            </OnUploadedConfirmDialog>
        </Box>
    );
}));

export default withAuth(Step3);
