import React from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DoneIcon from '@mui/icons-material/Done';
import theme from '../../../theme';

const SettingBar = ({activa} ) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  let tag , watermark = false;

  switch(activa){
    case "tag":
      tag = true;
      break;
    case "watermark":
      watermark = true;
      break;
  }
  const links = [
    {
      type:"text",
      title:t("Setting"),
    },
    {
      link: `/user/setting/tag`,
      label: t("Tag setting"),
      set: tag
    },
    {
      link: `/user/setting/watermark`,
      label: t("Watermark setting") ,
      set: tag

    },
  ]

  const handleNavigation = (path) => {
    navigate(path);
  };

  const matchPath = (link,sublinks) => {
    if( link === location.pathname ){
      return true;
    }
    if( sublinks && sublinks.length > 0){
      for( let i = 0; i < sublinks.length; i++){
        if( location.pathname.startsWith(sublinks[i])){
          return true;
        }
      }
    }
  };

  return (
    <Box sx={{ 
      width: 240,
      p:2,
      borderRightWidth:1,
      borderRightColor:theme.palette.grey[300],
      borderRightStyle:"solid",
      backgroundColor:theme.palette.grey[50],
      }} >
      {links.map((link,index) => (
        <Box key={index} >
          {link.type === "text" ? (<Typography className="stepTitle" variant='h5' sx={{mb:2,mt:2}}>{link.title}</Typography>) :
          <Box
            className={ matchPath(link.link,link.sublinks) ? 'active stepItem' : 'stepItem'}
            onClick={() => handleNavigation(link.link)}
          >
            <Typography variant='subtitle2' sx={{flex:1}}>{link.label}</Typography>
            {link.set && <DoneIcon color='primary' />}
          </Box>}
        </Box>
      ))}
    </Box>
  );
};

export default SettingBar;