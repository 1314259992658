import React, { useEffect, useState } from 'react';
import { TextField,Box,Button, Typography } from '@mui/material';
import { borderBottom } from '@mui/system';
import MessageBox from './MessageBox';
import {baseURL} from '../../http';

const TokenPanel = () => {
    const [token, setToken] = useState('');

    const handleInputChange = (e) => {
        setToken(e.target.value);
    };

    const handleSaveToken = () => {
        localStorage.setItem('admintoken', token);
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h2" style={{ marginBottom: '16px' }}> Admin Panel</Typography>
            <TextField 
                label="Enter token" 
                variant="outlined" 
                value={token} 
                onChange={handleInputChange} 
                style={{ marginBottom: '16px',width: '500px' }}
            />
            <Button variant="contained" color="primary" onClick={handleSaveToken}>
                Save Token
            </Button>
        </Box>
    );
};

const Admin = () => {
    const savedToken = localStorage.getItem('admintoken');
    const [apiData, setApiData] = useState(null);

    useEffect(() => {
        if (savedToken) {
            fetch(`${baseURL}/global/dashboard?token=${savedToken}`)
            .then(response => response.json())
            .then(data => {
                setApiData(data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
        }
    }, [savedToken]);

    if (!savedToken) {
        return <TokenPanel />;
    }

    const handleReply = (messageId, reply) => {
         fetch(`${baseURL}/global/message/${messageId}/reply`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({token:savedToken, content: reply })
        })
        .then(response => response.json())
        .then(data => {
            console.log('Reply submitted successfully:', data);
        })
        .catch(error => {
            console.error('Error submitting reply:', error);
        });
    };


    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h2" style={{ marginBottom: '16px' }}> Admin Panel</Typography>
            {apiData ? (
                <Box>
                    <Typography variant="h3">Total Income: {apiData.income.total}</Typography>
                    <Typography variant="subtitle">Total Picture: {apiData.pictureCount}</Typography>
                    <Box>
                        {apiData.income.incomOfThisMonth.map((monthData, index) => (
                            <Typography key={index} variant="body1">
                                {monthData.month}: {monthData.income}
                            </Typography>
                        ))}
                    </Box>
                    <Box style={{ marginTop: '16px' }}>
                        <Typography variant="h3">User Statistics</Typography>
                        <Typography variant="body1">Total Users: {apiData.users.count}</Typography>
                        <Typography variant="h5" style={{ marginTop: '16px' }}>Today's New Users</Typography>
                        {apiData.users.todayActiveUsers.length > 0 ? (
                            apiData.users.todayActiveUsers.map((user, index) => (
                                <Box key={index} style={{ marginBottom: '8px' }}>
                                    <Typography variant="body1">Id : {user.id}</Typography>
                                    <Typography variant="body1">Username: {user.username}</Typography>
                                    <Typography variant="body1">Phone: {user.phone}</Typography>
                                    <Typography variant="body1">Created At: {new Date(user.createTime).toLocaleString()}</Typography>
                                </Box>
                            ))
                        ) : (
                            <Typography variant="body1">No new users today.</Typography>
                        )}
                    </Box>
                    <Box>
                        <Typography variant="h3" style={{ marginTop: '16px' }}>Messages</Typography>
                        {apiData.messagesOfThisMonth.map((message, index) => {
                            return (
                                <MessageBox key={index} message={message} index={index} onReply={handleReply} />
                            );
                        })}
                    </Box>
                </Box>
               
            ) : (
                <Typography variant="body1">Loading...</Typography>
            )}
        </Box>
    );
}

export default Admin;