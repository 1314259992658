import React, { useState } from 'react';
import { Box, Typography, Button, TextField } from '@mui/material';
import { baseURL } from '../../http';

const MessageBox = ({ message, index, onReply ,token }) => {
    const [reply, setReply] = useState('');
    const [showReplyBox, setShowReplyBox] = useState(false);

    const handleReplyChange = (event) => {
        setReply(event.target.value);
    };

    const handleReplySubmit = () => {
        onReply(message.id, reply);
        setReply('');
        setShowReplyBox(false);
    };

    return (
        <Box key={index} id={`message-${index}`} sx={{ display:"flex", flexDirection:"row", justifyContent:"space-between",borderBottom:"1px solid black",padding:"10px" }}>
            <Box sx={{display:"flex",flexDirection:"column"}} >
                <Typography variant="body1">Date: {message.createdAt}</Typography>
                <Typography variant="body1">Message: {message.content}</Typography>
                <Typography variant="body1">Replies:</Typography>
                {message.replies.map((reply, index) => (
                    <Box key={index} style={{ marginLeft: '16px' }}>
                        <Typography variant="body1">Date: {reply.createdAt}</Typography>
                        <Typography variant="body1">Reply: {reply.content}</Typography>
                    </Box>
                ))}
           </Box>
           <Box>
            <Button variant="contained" color="secondary" onClick={() => setShowReplyBox(true)}>
                    Reply
                </Button>
                {showReplyBox && (
                    <Box mt={2}>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            variant="outlined"
                            placeholder="Type your reply here..."
                            value={reply}
                            onChange={handleReplyChange}
                        />
                        <Button variant="contained" color="primary" onClick={handleReplySubmit} style={{ marginTop: '8px' }}>
                            Submit Reply
                        </Button>
                    </Box>
                )}
           </Box>
        </Box>
    );
};

export default MessageBox;