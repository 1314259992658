import React, { useState, useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { inject, observer } from 'mobx-react';
import http from '../http';
import { Typography,Box } from '@mui/material';

const SpaceUsage = inject('userStore')(observer(({userStore}) => {
    const [progress, setProgress] = useState(0);
    useEffect(() => {
        http.get('/user/info').then((response) => {
            const progress = Math.min( Math.round((response.data.spaceUsed / response.data.spaceLimit) * 100), 100);
            userStore.setUserInfo(
                response.data
                );
            setProgress(progress);
        }).catch(error => {});
    }, [userStore]);

    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    }}>
        <LinearProgress variant="determinate" value={progress}   
        sx={{
            height: '8px',
            width: '100%', 
            borderRadius: '5px',
            backgroundColor: 'grey.300',
            '& .MuiLinearProgress-bar': {
                borderRadius: '5px',
                backgroundColor: 'primary.main',
            }
        }} />
        <Typography variant="body3" sx={{mt:1}} align="right">{userStore.spaceUsedInUnit} / {userStore.spaceLimitInUnit}</Typography>
    </Box>
}))

export default SpaceUsage;
