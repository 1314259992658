import React, { useState, useEffect } from 'react';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SettingBar from './SettingBar';
import withAuth from '../../../HOC/withAuth';

import http from '../../../http';

const Tag = () => {
    const { t } = useTranslation();
    const [tags, setTags] = useState([]);
    const [open, setOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [tagToDelete, setTagToDelete] = useState(null);
    const [tagToEdit, setTagToEdit] = useState(null);

    useEffect(() => {
        http.get('/user/tags')
            .then(response => {
                setTags(response.data);
            })
    }, [])

    const handleCreateTag = () => {
        setTagToEdit(null);
        setOpen(true);
    };

    const handleDeleteTag = (id) => {
        http.delete(`/user/tags/${id}`)
            .then(response => {
                setTags(tags.filter(tag => tag.id !== id));
            })
            .catch(error => {
                console.error('Error deleting tag:', error);
            });
    };

    const handleOpenDeleteDialog = (tag) => {
        setTagToDelete(tag);
        setDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setTagToDelete(null);
    };

    const handleConfirmDelete = () => {
        if (tagToDelete) {
            handleDeleteTag(tagToDelete.id);
            handleCloseDeleteDialog();
        }
    };

    const handleOpenEditDialog = (tag) => {
        setTagToEdit(tag);
        setOpen(true);
    };

    const handleSaveTag = (tag) => {
        if (tagToEdit) {
            http.put(`/user/tags/${tagToEdit.id}`, tag)
                .then(response => {
                    const updatedTags = tags.map(t => 
                        t.id === response.data.id ? response.data : t
                    );
                    setTags(updatedTags);
                })
                .catch(error => {
                    console.error('Error updating tag:', error);
                });
        } else {
            // Create new tag
            http.post('/user/tags', tag)
                .then(response => {
                    setTags([...tags, response.data]);
                })
                .catch(error => {
                    console.error('Error creating tag:', error);
                });
        }
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
            <SettingBar />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    p: 2,
                    margin: '10px auto',
                    background: 'white',
                    borderRadius: 1,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: 2,
                    }}
                >
                    <Typography variant="h3">{t('Custom Tag')}</Typography>
                    <Button variant="contained" color="primary" onClick={handleCreateTag}>
                        {t('Create Tag')}
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {tags.map((tag) => (
                        <Chip
                            key={tag.id}
                            label={tag.name}
                            sx={{ backgroundColor: tag.color, color: '#fff' }}
                            onClick={() => handleOpenEditDialog(tag)}
                            onDelete={() => handleOpenDeleteDialog(tag)}
                        />
                    ))}
                </Box>
            </Box>
            <TagModel
                open={open}
                setOpen={setOpen}
                onSaveTag={handleSaveTag}
                tagToEdit={tagToEdit}
            />
            <Dialog
                open={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
            >
                <DialogTitle>{t('Delete Tag')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('Are you sure you want to delete this tag?')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        {t('Cancel')}
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                        {t('Delete')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
const TagModel = ({ open, setOpen, onSaveTag, tagToEdit }) => {
    const colors = [
        '#FFC1C1', // Low saturation Red
        '#FFDAB5', // Low saturation Orange
        '#FFECB5', // Low saturation Yellow
        '#C1FFC1', // Low saturation Green
        '#C1FFFF', // Low saturation Cyan
        '#C1C1FF', // Low saturation Blue
        '#E5C1FF', // Low saturation Purple
        '#C1C1C1', // Low saturation Black
        '#FFFFFF', // Low saturation White
    ];

    const { t } = useTranslation();
    const [tagName, setTagName] = useState(tagToEdit ? tagToEdit.name : '');
    const [tagColor, setTagColor] = useState(tagToEdit ? tagToEdit.color : colors[0]);

    useEffect(() => {
        setTagName(tagToEdit ? tagToEdit.name : '');
        setTagColor(tagToEdit ? tagToEdit.color : colors[0]);
    }, [tagToEdit]);
    
    const [error, setError] = useState('');

 
    const handleSave = () => {
        if (!tagName.trim()) {
            setError(t('Tag name cannot be empty.'));
            return;
        }
        if (!tagColor) {
            setError(t('Please select a tag color.'));
            return;
        }
        setError('');
        onSaveTag({ name: tagName, color: tagColor });
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>{tagToEdit ? t('Edit Tag') : t('Create Tag')}</DialogTitle>
            <DialogContent>
                {error && (
                    <Typography color="error" variant="body2">
                        {error}
                    </Typography>
                )}
                <TextField
                    autoFocus
                    margin="dense"
                    label={t('Tag Name')}
                    type="text"
                    fullWidth
                    value={tagName}
                    onChange={(e) => setTagName(e.target.value)}
                    error={!tagName.trim()}
                    helperText={!tagName.trim() ? t('Tag name cannot be empty.') : ''}
                />
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
                    {colors.map((color) => (
                        <Box
                            key={color}
                            sx={{
                                width: 40,
                                height: 40,
                                backgroundColor: color,
                                borderRadius: '50%',
                                border:
                                    tagColor === color
                                        ? '2px solid black'
                                        : '2px solid transparent',
                                cursor: 'pointer',
                            }}
                            onClick={() => setTagColor(color)}
                        />
                    ))}
                </Box>
                {!tagColor && (
                    <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                        {t('Please select a tag color.')}
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">
                    {t('Cancel')}
                </Button>
                <Button onClick={handleSave} color="primary">
                    {t('Save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withAuth(Tag);